import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import QrGen from "./pages/QRgen";
import TabsLayout from "./components/layout/TabsLayout";
import StickerGen from "./pages/Sticker";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TabsLayout item="qr"><QrGen/></TabsLayout>}/>
          <Route path="/qr" element={<TabsLayout item="qr"><QrGen/></TabsLayout>}/>
          <Route path="/sticker" element={<TabsLayout item="sticker"><StickerGen/></TabsLayout>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
