// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr_qrWrapper__yIz\\+V svg {
    width: 100%;
}

.qr_textArea__Hv6wU textarea {
    min-height: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/QRgen/qr.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".qrWrapper svg {\n    width: 100%;\n}\n\n.textArea textarea {\n    min-height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qrWrapper": `qr_qrWrapper__yIz+V`,
	"textArea": `qr_textArea__Hv6wU`
};
export default ___CSS_LOADER_EXPORT___;
