import {Button, Flex, Grid, NumberInput} from "@mantine/core";
import React, {useState} from "react"
import RichTextarea from "./RichText";

import gridStyles from "../../components/layout/sharedStyles/grid.module.css"

// @ts-ignore
import html2pdf from "html-to-pdf-js"

import styles from "./sticker.module.css"
import useIsMobile from "../../hooks/useIsMobile";
import cx from "classnames";
import {useLocalStorage} from "@mantine/hooks";

const StickerGen = () => {
  const [value, setValue] = useState("")

  const [width, setWidth] = useLocalStorage({key: "sticker-width", defaultValue: 48})
  const [height, setHeight] = useLocalStorage({key: "sticker-height", defaultValue: 120})
  const [padding, setPadding] = useLocalStorage({key: "sticker-padding", defaultValue: 5})

  const openPdf = () => {
    const element = document.getElementById('element-to-print');

    if (!element) return

    element.style.display = "block"

    html2pdf().set({
      html2canvas: {scale: 5},
      jsPDF: {
        format: [width, height]
      }
    }).from(element).toPdf().output('blob').then((data: any) => {

      element.style.display = "none"
      const src = URL.createObjectURL(data)
      window.open(src)
    })
  }

  const isMobile = useIsMobile()

  const stickerStyles = {
    height: `${height}mm`,
    width: `${width}mm`,
    padding: `${padding}mm`,
    aspectRatio: `${width / height}`
  }

  const previewStyles = {
    padding: `${padding}mm`,
    aspectRatio: `${width / height}`
  }

  return (
    <div className={cx(gridStyles.grid, {[gridStyles.mobile]: isMobile})}>
      <div>
        <Flex mb="md" gap="1rem">
          <NumberInput label="Width (mm)" min={0} step={5} defaultValue={width} onChange={(val) => setWidth(parseInt(val.toString()))}/>
          <NumberInput label="Height (mm)" min={0} step={10} defaultValue={height} onChange={(val) => setHeight(parseInt(val.toString()))}/>
          <NumberInput label="Padding (mm)" min={0} step={1} defaultValue={padding} onChange={(val) => setPadding(parseInt(val.toString()))}/>
        </Flex>

        <RichTextarea content={value} onChange={setValue}/>
      </div>
      <div>
        <div className={styles.preview} style={previewStyles} dangerouslySetInnerHTML={{__html: value}}></div>

        <Button variant="default" onClick={openPdf}>Download PDF</Button>

        <div style={{display: "none", ...stickerStyles}} id="element-to-print" className={styles.sticker}>
          <div className={styles.rect}></div>
          <div dangerouslySetInnerHTML={{__html: value}}></div>
        </div>
      </div>
    </div>
  )
}

export default StickerGen
