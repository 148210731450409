import {Box, Container, rem, Tabs, Title} from "@mantine/core"
import React from "react"
import {IconQrcode, IconSticker} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";

interface Props {
  children: React.ReactNode
  item: string
}

const TabsLayout = (props: Props) => {
  const iconStyle = {width: rem(12), height: rem(12)};

  const navigate = useNavigate()

  return (
    <>
      <Container mt="xl">
        <Title>Generator</Title>

        <Tabs variant="outline" defaultValue="gallery" mt="xl" value={props.item}>
          <Tabs.List>
            <Tabs.Tab value="qr" onClick={() => navigate("/qr")} leftSection={<IconQrcode style={iconStyle}/>}>
              QR code
            </Tabs.Tab>
            <Tabs.Tab value="sticker" onClick={() => navigate("/sticker")} leftSection={<IconSticker style={iconStyle}/>}>
              POS Sticker Label
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
      </Container>
      <Container mt="md">
        {props.children}
      </Container>
      <Container mt="5rem" ta="right">
        <Box opacity={0.2}>
          Made by: Matěj Půhoný {new Date().getFullYear()}
        </Box>
      </Container>
    </>
  )
}

export default TabsLayout
