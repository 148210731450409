// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid_grid__iOfWO {
    display: grid;
    grid-template-columns: 9fr 3fr;
    gap: 1rem;
}

.grid_grid__iOfWO.grid_mobile__3jjKe {
    grid-template-columns: 12fr;
    gap: 3rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/sharedStyles/grid.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,2BAA2B;IAC3B,SAAS;AACb","sourcesContent":[".grid {\n    display: grid;\n    grid-template-columns: 9fr 3fr;\n    gap: 1rem;\n}\n\n.grid.mobile {\n    grid-template-columns: 12fr;\n    gap: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `grid_grid__iOfWO`,
	"mobile": `grid_mobile__3jjKe`
};
export default ___CSS_LOADER_EXPORT___;
