import React, {useRef, useState} from "react"
//@ts-ignore
import {QRCode} from "react-qr-code"
import {Box, Button, Container, em, Flex, Grid, Group, Input, Textarea, Title} from "@mantine/core";
import cx from "classnames"
import {useMediaQuery} from "@mantine/hooks";

import styles from "./qr.module.css"
import gridStyles from "../../components/layout/sharedStyles/grid.module.css"
import useIsMobile from "../../hooks/useIsMobile";


const SMS_TEMPLATE = "SMSTO:123456789:message with gaps"
const EVENT_TEMPLATE = `BEGIN:VEVENT
SUMMARY:Akce (name)
DTSTART:20240607T000000Z (time is not required)
DTEND:20240607T130000Z (time is not required)
LOCATION:Pardubice
DESCRIPTION:popisek cool
END:VEVENT
`

const QrGen = () => {
  const [value, setValue] = useState("")

  const inputRef = useRef<any>()

  const setValueTemplate = (template: string) => {
    setValue(template)
    if (inputRef.current) inputRef.current.value = template
  }

  const handleSVG = () => {
    const svg = document.getElementById("QRCodeScaled")
    if (!svg) return

    const svgData = new XMLSerializer().serializeToString(svg)

    const blob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a")
    downloadLink.download = `QR-CODE-${new Date().getTime()}.svg`
    downloadLink.href = url
    downloadLink.click()
  }

  const handlePng = () => {
    const svg = document.getElementById("QRCodeScaled")
    if (!svg) return

    const svgData = new XMLSerializer().serializeToString(svg)

    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D
    const img = new Image()

    img.onload = () => {
      canvas.width = 1024 + 20
      canvas.height = 1024 + 20

      ctx.translate(0.5, 0.5)

      ctx.rect(0, 0, canvas.width, canvas.height)
      ctx.fillStyle = "white"
      ctx.fill()

      ctx.drawImage(img, 0, 0, img.width, img.height, 10, 10, 1024, 1024)

      const pngFile = canvas.toDataURL("image/png")
      const downloadLink = document.createElement("a")
      downloadLink.download = `QR-CODE-${new Date().getTime()}.png`
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  const isMobile = useIsMobile()

  return (
    <>
      <div className={cx(gridStyles.grid, {[gridStyles.mobile]: isMobile})}>
        <div className={styles.inputs}>
          <Input.Wrapper label="QR code template" mt="md">
            <Group mb="md" gap="0.25rem">
              <Button variant="default" onClick={() => setValueTemplate(SMS_TEMPLATE)}>Send
                SMS</Button>
              <Button variant="default" onClick={() => setValueTemplate(EVENT_TEMPLATE)}>Event</Button>
            </Group>
          </Input.Wrapper>

          <Textarea className={styles.textArea} ref={inputRef} label="Obsah QR" defaultValue={value}
                    onChange={(e) => setValue(e.target.value)}/>
        </div>
        <div className={styles.preview}>
          <Box className={styles.qrWrapper}>
            <QRCode value={value}/>

            <div style={{display: "none"}}>
              <QRCode
                id="QRCodeScaled"
                value={value}
                size={1024 * 2}
              />
            </div>
          </Box>

          <Box>
            <Button variant="default" fullWidth mb="xs" onClick={handlePng}>Stáhnout PNG</Button>
            <Button variant="default" fullWidth mb="xs" onClick={handleSVG}>Stáhnout SVG</Button>
          </Box>
        </div>
      </div>
    </>
  )
}

export default QrGen
