// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticker_sticker__t3Mc7 {
    width: 48mm;
    height: 210mm;
    display: block;
    position: relative;
}

.sticker_preview__kVvkN {
    aspect-ratio: 48 / 210;
    width: 100%;
    border: 1px solid gray;
    padding: 3mm;
    margin-bottom: 1rem;
}

.sticker_preview__kVvkN *, .sticker_sticker__t3Mc7 * {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    min-height: 1em;
}

.sticker_rect__z7RHi {
    position: absolute;
    top: 0;
    left: 0;
    height: 28mm;
    width: 3mm;
    background-color: #fff;
    opacity: 0.1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Sticker/sticker.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,UAAU;IACV,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".sticker {\n    width: 48mm;\n    height: 210mm;\n    display: block;\n    position: relative;\n}\n\n.preview {\n    aspect-ratio: 48 / 210;\n    width: 100%;\n    border: 1px solid gray;\n    padding: 3mm;\n    margin-bottom: 1rem;\n}\n\n.preview *, .sticker * {\n    margin-top: 0;\n    margin-bottom: 0;\n    line-height: 1;\n    min-height: 1em;\n}\n\n.rect {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 28mm;\n    width: 3mm;\n    background-color: #fff;\n    opacity: 0.1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sticker": `sticker_sticker__t3Mc7`,
	"preview": `sticker_preview__kVvkN`,
	"rect": `sticker_rect__z7RHi`
};
export default ___CSS_LOADER_EXPORT___;
